import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import PostAuthor from "../components/PostAuthor";
import createTwitterLink from "../../utils/createTwitterLink";
import Footer from "../components/footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import Header from "../components/header";
import colors from "../components/colors";
import H1 from "../components/styled/H1";
import H4 from "../components/styled/H4";
import P2 from "../components/styled/P2";
import Button from "../components/button";
import BlogSubheader from "../components/BlogSubheader";
import "../components/layout.css";
import PopupWrapper from "../components/popupWrapper";
import Card from "../components/layout/Card";
import BlogPostBody from "../components/BlogPostBody";

const BlogPostFooter = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
`;

const Buttons = styled.div`
  a {
    margin: 1em 0.5em;
  }
`;

const authors = {
  fabrizio: {
    name: "Fabrizio",
    twitter: "linuz90"
  },
  francesco: {
    name: "Francesco",
    twitter: "frankdilo"
  }
};

export default function Template(props) {
  const { data } = props;
  const [subscribePopupShown, setSubscribePopupShown] = useState(false);

  const { frontmatter, html } = data.thisPost;

  let thisPostIndex = data.allPosts.edges.findIndex(post => post.node.frontmatter.title == data.thisPost.frontmatter.title);

  const prevPost = data.allPosts.edges[thisPostIndex - 1];
  const nextPost = data.allPosts.edges[thisPostIndex + 1];

  const tweetURL = createTwitterLink(
    `${frontmatter.title}, by @${authors[frontmatter.author].twitter}`,
    `https://unread.it${frontmatter.path}`
  );

  let blog = false;

  if (frontmatter.author) blog = true;

  let canonical_url = null;

  if (frontmatter.canonical_base_url) {
    canonical_url = frontmatter.canonical_base_url + frontmatter.path;
  } else {
    canonical_url = props.location.href;
  }

  let card = null;

  if (frontmatter.card) {
    card = `https://www.boxysuite.com/cards/${frontmatter.card}.jpg`;
  }

  return (
    <LayoutWrapper>
      <SEO title={frontmatter.metaTitle || frontmatter.title} description={frontmatter.excerpt} canonical={canonical_url} card={card} />
      <Header />
      <BlogSubheader />
      <LayoutSection small>
        <PostAuthor frontmatter={frontmatter} />
        <H1 left color="ultra_dark">
          <Link to={frontmatter.path} style={{ textDecoration: "none" }}>
            {frontmatter.title}
          </Link>
        </H1>
        <BlogPostBody dangerouslySetInnerHTML={{ __html: html }} />
        {blog && (
          <BlogPostFooter>
            <Buttons>
              <Button secondary small label="Share on Twitter" href={tweetURL} id="3" />
            </Buttons>

            {nextPost && (
              <P2 style={{ marginTop: "2em" }}>
                Read next: <Link to={nextPost.node.frontmatter.path}>{nextPost.node.frontmatter.title}</Link>
              </P2>
            )}
          </BlogPostFooter>
        )}
      </LayoutSection>
      <Footer />
    </LayoutWrapper>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    thisPost: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        excerpt
        title
        metaTitle
        legal
        author
      }
    }
    allPosts: allMarkdownRemark(
      filter: { frontmatter: { author: { ne: null } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            legal
            metaTitle
            layout
            author
          }
        }
      }
    }
  }
`;
